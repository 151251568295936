body {
  font-family: 'Roboto', sans-serif;
}

html, body, #vue-app {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

[v-cloak] {
    display: none;
}