
.intro-content {
    img {
        max-width: 100%;
    }

    h1 {
        text-align: center;
    }

    label {
        margin: 0 5px 0 0;
    }

    .continue {
        text-align: center;
        margin-top: 16px;
    }
}
