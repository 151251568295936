
.glacier-map-interface {
    width: 100%;
    height: 100%;

    iframe {
        width: 100%;
        height: 100%;
    }
}


.frame {
    width: 100%;
    height: 100%;
    position: relative;
}

#map1, #map2, #map3 {
    width: 100%; height: 100%;
}

.map-overlay-content {
    position: absolute;
    top: 70px; right: 15px;
    border: 1px solid #323232;
    background-color: white;

    width: 205px;
    max-height: 80%;
    overflow-y: auto;

    padding: 4px;
    font-size: 0.8rem;

}

.content-window {
    position: absolute;
    z-index: 9999;
    top: 0; right: 0; bottom: 0; left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    > div:first-of-type {
        width: 1000px;
        max-width: 95%;
        max-height: 95%;
        overflow-y: auto;
        padding: 15px;
        background-color: white;
        border: 1px solid grey;

        img {
            max-width: 100%;
        }
    }
}

.content-window-background {
    background-color: rgba(33, 142, 204, 0.1);
}

.split-content {
    display: flex;
    align-items: stretch;
    justify-items: center;

    div:first-of-type {

    }

    div:last-of-type {
        margin-left: 15px;

    }
}

.confirm-sketch {
    position: absolute;
    top: 5px; left: 50%;
    transform: translate(-50%);
    z-index:9999;
    width: 390px;
    max-width: 95%;
    text-align: justify;

    border: 1px solid grey;
    background-color: white;
    padding: 5px;
    font-size: 0.8rem;

    p:last-of-type {
      margin: 5px 0 0 0;

        button:first-of-type {
            float: left;
        }
        button:last-of-type {
            float: right;
        }
    }
}

.final-image {
    position: absolute;
    z-index: 9990;
    top: 0; right: 0; bottom: 0; left: 0;
    background-size: cover;
}

.instructions-modal {
    position: absolute;
    z-index: 9999;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */

    > div {
        position: relative;
        padding: 15px;
        background-color: white;
        border: 1px solid grey;
        max-width: 400px;
        max-height: 90%;
        overflow-y: scroll;

        div {
            position: absolute;
            top: 0; right: 10px;
            font-size: 180%;
            cursor: pointer;
        }
    }
}

