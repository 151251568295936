
.visibility-message {
    font-size: 90%;
    cursor: pointer;
    font-weight: bold;

    &:hover {
        text-decoration: underline;
    }
}
